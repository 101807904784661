import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cards from "../components/cards/cards"
import Video from "../components/video/video"
import bgVideo from '../components/video/04_THS_Animatic_Final_Ticker-Banquet-Compressed.mp4'
import videoPoster from "../components/video/video-poster-ticker-banquet.jpg"

const TickerTapeBanquet = () => (
  <Layout>
    <SEO title="Ticker Tape Banquet" />
    <div className="cardsContainer cardsContainer--ticker-tape-banquet">
        <Cards postCount={5} hero={false} />
        <Video src={bgVideo} poster={videoPoster} />
    </div>
  </Layout>
)

export default TickerTapeBanquet
